import React from "react";
import Copyright from "./copyright";

import { ReactComponent as VisaLogo } from "./logos/visa.svg";
import { ReactComponent as Mastercard } from "./logos/mastercard.svg";
import StripeLogo from "./logos/stripe";
import { ReactComponent as PaypalLogo } from "./logos/paypal.svg";

import "./index.scss";

export default () => (
  <div>
    <div className="footer">
      <div className="footer__inner app-container">
        <div className="footer__column footer__contact">
          <h3>Contact</h3>
          <p>2820 Old Lincoln Highway, 1 A, Feasterville-Trevose, PA, United States, 19053</p>
          <ul>
            <li>+1 267 800 90 48</li>
            <li>+38 050 3260503</li>
            <li>+38 068 2260503</li>
            <li>+38 093 2260503</li>
          </ul>
          <a href="mailto:market@seller-online.net">market@seller-online.net</a>
        </div>
        <div className="footer__column footer__socials">
          <h3>Follow SOL</h3>
          <ul>
            <li>
              {/* <img src="" alt="" /> */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
              </svg>
              <a href="https://www.facebook.com/supergiftsmarket/">Facebook</a>
            </li>
            <li>
              {/* <img src="" alt="" /> */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z" />
              </svg>
              <a href="https://www.instagram.com/selleronline_official/">Instagram</a>
            </li>
            <li>
              {/* <img src="" alt="" /> */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path d="M256 597q0-108 37.5-203.5t103.5-166.5 152-123 185-78 202-26q158 0 294 66.5t221 193.5 85 287q0 96-19 188t-60 177-100 149.5-145 103-189 38.5q-68 0-135-32t-96-88q-10 39-28 112.5t-23.5 95-20.5 71-26 71-32 62.5-46 77.5-62 86.5l-14 5-9-10q-15-157-15-188 0-92 21.5-206.5t66.5-287.5 52-203q-32-65-32-169 0-83 52-156t132-73q61 0 95 40.5t34 102.5q0 66-44 191t-44 187q0 63 45 104.5t109 41.5q55 0 102-25t78.5-68 56-95 38-110.5 20-111 6.5-99.5q0-173-109.5-269.5t-285.5-96.5q-200 0-334 129.5t-134 328.5q0 44 12.5 85t27 65 27 45.5 12.5 30.5q0 28-15 73t-37 45q-2 0-17-3-51-15-90.5-56t-61-94.5-32.5-108-11-106.5z" />
              </svg>
              <a href="https://seller-online.net/">Pinterest</a>
            </li>
            <li>
              {/* <img src="" alt="" /> */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z" />
              </svg>
              <a href="https://seller-online.net/">Twitter</a>
            </li>
          </ul>
        </div>
        <div className="footer__column footer__about">
          <h3>About</h3>
          <p>
            We have millions of one-of-a-kind items, so you can find whatever you need (or really,
            really want). Buy directly from someone who put their heart and soul into making
            something special. We use best-in-className technology to protect your transactions.
          </p>
        </div>
        <div className="footer__column footer__legal">
          <h3>Legal Info</h3>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seller-online.net/disclaimer"
              >
                Disclaimer
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://seller-online.net/terms">
                Terms of Use
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seller-online.net/return-policy"
              >
                Return Policy
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seller-online.net/privacy"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://seller-online.net/prohibited-products"
              >
                Prohibited Product List
              </a>
            </li>
          </ul>
          <div className="footer__logos">
            <a href="https://visa.com/" target="_blank" rel="noopener noreferrer" className="logo">
              <VisaLogo />
            </a>
            <a
              href="https://www.mastercard.us/"
              target="_blank"
              rel="noopener noreferrer"
              className="logo"
            >
              <Mastercard />
            </a>
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="logo"
            >
              <StripeLogo />
            </a>
            <a
              href="https://paypal.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="logo logo--paypal"
            >
              <PaypalLogo />
            </a>
          </div>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
);
