import React from "react";
import "./index.scss";
import PaypalLogo from "./logo";

const PayPalButton = ({ link }) => {
  return (
    <div className="container">
      <a href={link} className="braintree-paypal-button">
        <span className="button-text">Pay with </span>
        {<PaypalLogo height={25} width={94} />}
      </a>
    </div>
  );
};

export default PayPalButton;
