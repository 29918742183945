/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { formatPrice, ErrorResult, Message } from "./utils";
import axios from "axios";
import ProductCard from "./components/ProductCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";

import Header from "./layout/Header";
import Footer from "./layout/Footer/index";

// loading stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const App = () => {
  const [etsyData, setEtsyData] = useState([]);
  const serverURL = process.env.REACT_APP_SERVER;

  /* 0 for initial state, 1 for successfully loaded, -1 for error */
  const [pageState, setPageState] = useState(0);
  const [errorMsg, setErrorMsg] = useState("Error happened...");
  const [shopName, setShopName] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [checkoutStage, setCheckoutStage] = useState(0);
  const [reloadTimer, setReloadTimer] = useState(-1);
  const [fullInvoice, setFullInvoice] = useState(null);

  useEffect(() => {
    if (window && window.location) {
      const params = window.location.search;

      if (!params || !params.length) {
        setPageState(-1);
        setErrorMsg(
          "Sorry, the link is invalid. Please contact a support team..."
        );
        return;
      }

      // parsing data for request
      // check if this POST-payment page
      let mainParam = "";

      const postParams = new URL(document.location).searchParams;
      if (postParams.get("result") && postParams.get("invoice")) {
        mainParam = postParams.get("invoice");
        setCheckoutStage(postParams.get("result") === "success" ? 2 : -1);
      } else if (postParams.get("invoice")) {
        mainParam = postParams.get("invoice");
      } else {
        mainParam = params.substr(1).split("&")[0] || "";
      }

      // if url contains payment link identifier
      let reLink = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/,
        isLinkUuid = reLink.test(mainParam);

      if (isLinkUuid) {
        setFullInvoice(mainParam);

        (function getCheckoutLinkData() {
          axios
            .get(`${serverURL}/get-payment-link-info/${mainParam}`)
            .then(({ data }) => {
              // assigning fetched items
              setEtsyData(data);
              // changing status to `loaded successfully`
              setPageState(2);
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                axios.post(serverURL + "/log-error", {
                  invoice: mainParam,
                  message: error.response.data.message,
                });
                setErrorMsg(`${error.response.data.message}`);
              } else if (error.message) {
                axios.post(serverURL + "/log-error", {
                  invoice: mainParam,
                  message: error.message,
                });
                setErrorMsg("Unfortunately, payment link request failed.");
                setReloadTimer(60);
              } else {
                axios.post(serverURL + "/log-error", {
                  invoice: mainParam,
                  message: error,
                });
                setErrorMsg("Unfortunately, payment link request failed.");
                setReloadTimer(60);
              }

              setPageState(-1);
              return;
            });
        })();

        return;
      }

      // if url contains shop name
      let re = /^[A-Za-z0-9]{3,}$/,
        isShopName = re.test(mainParam);

      if (isShopName) {
        setShopName(mainParam);
        setPageState(1);
        return;
      }

      // if it's not a shopname, check if it's a valid invoice number
      const isValidInvoice = /^[0-9]+_[0-9]+$/.test(mainParam);
      if (!isValidInvoice) {
        setPageState(-1);
        setErrorMsg(
          "Sorry, the link is invalid. Please contact a support team..."
        );
        return;
      }

      // if link contain shop_id && invoice
      const parts = mainParam.split("_");
      const [shop, receipt] = parts;

      if (!fullInvoice) {
        setFullInvoice(mainParam);
      }

      // fetching API
      (function getEtsyData() {
        axios
          .get(`${serverURL}/etsy-data/${shop}/${receipt}`)
          .then(({ data }) => {
            // assigning fetched items
            setEtsyData(data.payment_data);
            // changing status to `loaded successfully`
            setPageState(2);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              axios.post(serverURL + "/log-error", {
                invoice: mainParam,
                message: error.response.data.message,
              });
              setErrorMsg(`Request failed: ${error.response.data.message}`);
            } else if (error.message) {
              axios.post(serverURL + "/log-error", {
                invoice: mainParam,
                message: error.message,
              });
              setErrorMsg("Unfortunately, Etsy request failed.");
              setReloadTimer(60);
            } else {
              axios.post(serverURL + "/log-error", {
                invoice: mainParam,
                message: error,
              });
              setErrorMsg("Unfortunately, Etsy request failed.");
              setReloadTimer(60);
            }

            setPageState(-1);
            return;
          });
      })();
    }
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined" && fullInvoice) {
      try {
        axios.post(serverURL + "/log-referrer", {
          invoice: fullInvoice,
          message: document.referrer || "",
        });
      } catch (e) {}
    }
  }, [fullInvoice, document]);

  useEffect(() => {
    if (!setReloadTimer || !reloadTimer) return;
    if (reloadTimer > 1) {
      setTimeout(() => {
        setReloadTimer((reloadTimer) => reloadTimer - 1);
      }, 1000);
    } else if (reloadTimer === 1) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [reloadTimer, setReloadTimer]);

  const requestCheckoutLink = (e) => {
    e.preventDefault();

    let origin = window.location.origin,
      numOnlyReg = new RegExp("^\\d+$");

    if (!invoiceNumber) {
      alert("Please enter invoice ID");
      return;
    }

    if (!numOnlyReg.test(invoiceNumber)) {
      alert("Invoice can contain only numbers");
      return;
    }

    setLoading(true);

    axios
      .get(`${serverURL}/etsy-data/${shopName}/`)
      .then(({ data }) => {
        if (data.success) {
          window.location.replace(
            `${origin}/?${data.shop_id}_${invoiceNumber}`
          );
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          axios.post(serverURL + "/log-error", {
            invoice: shopName,
            message: error.response.data.message,
          });
          alert(error.response.data.message);
          setLoading(false);
        } else if (error.message) {
          axios.post(serverURL + "/log-error", {
            invoice: shopName,
            message: error.message,
          });
          alert("Unfortunately, Etsy request failed.");
          setLoading(false);
        } else {
          axios.post(serverURL + "/log-error", {
            invoice: shopName,
            message: error,
          });
          alert("Unfortunately, Etsy request failed.");
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="App">
      <Header></Header>
      <div className="main app-container">
        {pageState === 1 && (
          <form onSubmit={(e) => requestCheckoutLink(e)} className="get-shop">
            <h2>
              You can pay Etsy order for shop: <b>{shopName}</b>
            </h2>
            <div className="get-shop__form">
              <div className="form-group">
                <label htmlFor="">Enter invoice number:</label>
                <input
                  readOnly={loading}
                  onChange={(e) =>
                    setInvoiceNumber((e.target.value || "").replace(/\D/g, ""))
                  }
                  type="text"
                  value={invoiceNumber}
                />
              </div>
              <button
                className="get-shop__next-button"
                type="submit"
                disabled={loading}
              >
                Next step
              </button>
            </div>
            <div className="get-shop__help">
              <div
                onClick={() => setShowHelp(!showHelp)}
                className="get-shop__show"
              >
                Where can I find the invoice number ?
              </div>
              {showHelp && <img src={require("./img/example.png")} alt="" />}
            </div>
          </form>
        )}
        {pageState === 2 && (
          <div className="app-container">
            <div className="section-div">
              <h2>
                {etsyData.payment_link
                  ? "Online Checkout"
                  : "Payment method Other"}
              </h2>
            </div>
            <div className="section-div">
              {etsyData.items.map((item, i) => (
                <ProductCard
                  currency={etsyData.currency}
                  key={`product-${item.listing_id}-${i}`}
                  product={item}
                />
              ))}
            </div>
            <div className="payment-data">
              <div className="payment-data__desc">
                <div>
                  Here you can pay for your invoice <u>{etsyData.invoice}</u>
                </div>
                {etsyData.shop_name && (
                  <div>
                    <span>Shop name: </span>
                    <u>{etsyData.shop_name}</u>
                  </div>
                )}
                {etsyData.ship_to_city && (
                  <div>
                    <span>Ship to:</span> <u>{etsyData.ship_to_city}</u>
                  </div>
                )}
              </div>
              <div className="payment-data__total">
                <div className="payment-data__total-row">
                  Total:{" "}
                  <span className="price-span">
                    {formatPrice(etsyData.amount, etsyData.currency)}
                  </span>
                </div>
                {etsyData.discount > 0 && (
                  <div className="payment-data__total-row">
                    Discount:{" "}
                    <span className="price-span">
                      {formatPrice(etsyData.discount, etsyData.currency)}
                    </span>
                  </div>
                )}
                {etsyData.tax > 0 && (
                  <div className="payment-data__total-row">
                    Tax:{" "}
                    <span className="price-span">
                      {formatPrice(etsyData.tax, etsyData.currency)}
                    </span>
                  </div>
                )}
                {etsyData.vat > 0 && (
                  <div className="payment-data__total-row">
                    VAT:{" "}
                    <span className="price-span">
                      {formatPrice(etsyData.vat, etsyData.currency)}
                    </span>
                  </div>
                )}

                <div className="payment-data__total-row">
                  Shipping:{" "}
                  <span className="price-span">
                    {formatPrice(etsyData.shipping_cost, etsyData.currency)}
                  </span>
                </div>
                <div className="payment-data__total-row">
                  To pay:{" "}
                  <span className="payment-data__to-pay price-span">
                    {formatPrice(etsyData.total_amount, etsyData.currency)}
                  </span>
                </div>
              </div>
            </div>
            <div className="stripe-request">
              {etsyData && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    rawData={etsyData}
                    initialStage={checkoutStage}
                    invoice={fullInvoice}
                  />
                </Elements>
              )}
            </div>
          </div>
        )}
        {pageState === -1 && <ErrorResult>{errorMsg}</ErrorResult>}
        {reloadTimer > 0 && (
          <Message>
            Please try to reload the page, or it will be reloaded automatically
            in <br />
            <span className="error">{reloadTimer}</span> seconds...
          </Message>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default App;
