import React from "react";
import "./index.scss";

const ToggleButtonGroup = ({ buttons, func, active, styles }) => {
  return (
    <div className="tgl-btn-grp" style={styles || {}}>
      <radiogroup>
        {buttons &&
          buttons.map((btn) => (
            <div>
              <input
                id={`toggle-btn-${btn.label}`}
                key={`toggle-btn-${btn.label}`}
                type="radio"
                className="tgl-btn-grp__radio"
                onChange={() => func(btn.value)}
                checked={btn.value === active}
              />
              <label
                htmlFor={`toggle-btn-${btn.label}`}
                className="tgl-btn-grp__label"
                title={btn.title}
              >
                {btn.label}
              </label>
            </div>
          ))}
      </radiogroup>
    </div>
  );
};

export default ToggleButtonGroup;
