import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "./index.scss";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../styles.scss";
import {
  formatPrice,
  ErrorResult,
  SuccessResult,
  decodeHTML,
  getBraintreeErrorMessage,
  isNodeEmpty,
} from "../../utils";
import PayPalButton from "../PayPalButton";
import ToggleButtonGroup from "../ToggleButtonGroup";
import dropin from "braintree-web-drop-in";
import { useForm } from "react-hook-form";
import { Fragment } from "react";
import CountryList from "../countryList";
import PaymentIcons from "./paymentIcons";

const ELEMENT_OPTIONS = {
  style: {
    paymentRequestButton: {
      type: "buy",
      theme: "dark",
    },
  },
};

const ADDRESSFORMFIELDS = [
  {
    type: "input",
    name: "firstName",
    displayName: "first name",
    required: true,
    validation: /^\S+.{0,30}\S$/i,
  },
  {
    type: "input",
    name: "lastName",
    displayName: "last name",
    required: true,
    validation: /^\S+.{0,30}\S$/i,
  },
  {
    type: "input",
    name: "email",
    displayName: "email",
    required: true,
    validation: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  {
    type: "select",
    name: "country",
    displayName: "country",
    required: true,
    options: CountryList.map((country) => (
      <option key={`option-coutry-${country.code}`} value={country.code}>
        {country.name}
      </option>
    )),
  },
  {
    type: "input",
    name: "city",
    displayName: "city",
    required: true,
    validation: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/i,
  },
  {
    type: "input",
    name: "street1",
    displayName: "street line 1",
    required: true,
    validation: /^\S+.{0,30}\S$/i,
  },
  {
    type: "input",
    name: "street2",
    displayName: "street line 2",
    required: false,
    validation: /^\S+.{0,30}\S$/i,
  },
  {
    type: "input",
    name: "state",
    displayName: "state / region",
    required: false,
    validation: /^\S+.{0,30}\S$/i,
  },
  {
    type: "input",
    name: "zip",
    displayName: "zip / postal code",
    required: "Type valid ZIP or 00000 if doesn't exist",
    validation: /^\S+.{0,15}\S$/i,
    error: "Type valid ZIP or 00000 if doesn't exist",
  },
];

const CheckoutForm = ({ rawData, initialStage, invoice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const serverURL = process.env.REACT_APP_SERVER;
  const [useBraintree, setUseBrainrtee] = useState(
    process.env.REACT_APP_USE_BRAINTREE && !!rawData.currency_rate
  );
  const [data, setData] = useState(rawData);
  const [paymentRequest, setPaymentRequest] = useState(null); // for payment request button
  const [errorMessage, setErrorMessage] = useState(null); // error msg to display instead of form
  const [buttonPayment, setButtonPayment] = useState(true); // use whether PRB or card checkout
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Congratulations! Your payment was successful!"
  );

  // shipping address and contact details
  const { handleSubmit, register, errors, setError, clearErrors } = useForm();
  const [phoneNumber, setPhoneNumber] = useState(""); // phone number for shipping info
  const [phoneError, setPhoneError] = useState(true);
  const [addressConfirmed, setAddressConfirmed] = useState(false);

  // 0 for init, 1 for process, 2 for success, -1 for error
  const [pageState, setPageState] = useState(initialStage);
  // initial value for toggle button group
  const [paymentService, setPaymentService] = useState(
    useBraintree ? "braintree" : "stripe"
  );
  const [braintreeToken, setBraintreeToken] = useState(null);

  // braintree section
  const [braintreeButtonDisabled, setBraintreeButtonDisabled] = useState(true);
  const [braintreeRequestProcessing, setBraintreeRequestProcessing] = useState(
    false
  );
  const braintreeButtonRef = useRef(null);
  const [hideBraintree, setHideBraintree] = useState(true);
  const [paypalBtnCreating, setPaypalBtnCreating] = useState(false);
  const [customPaymentLink, setCustomPaymentLink] = useState(data.paypal_link);

  const logError = useCallback(
    async (message) => {
      axios.post(serverURL + "/log-error", {
        invoice: invoice || "unknown invoice",
        message: message,
      });
    },
    [invoice, serverURL]
  );

  const onAddressSubmit = useCallback(
    (values) => {
      setData({
        ...data,
        shipping: {
          city: values.city.replace(/\s+/g, " ").trim(),
          country_iso: values.country.replace(/\s+/g, " ").trim(),
          first_line: values.street1.replace(/\s+/g, " ").trim(),
          name: `${values.firstName || ""} ${values.lastName || ""}`
            .replace(/\s+/g, " ")
            .trim(),
          second_line: (values.street2 || "").replace(/\s+/g, " ").trim(),
          state: (values.state || "").replace(/\s+/g, " ").trim(),
          zip: parseFloat(values.zip) ? values.zip : null,
        },
      });
      setAddressConfirmed(true);
    },
    [data, setAddressConfirmed, setData]
  );

  /**
   * check if there is initial error
   */
  useEffect(() => {
    if (initialStage === -1) {
      setErrorMessage("The payment was cancelled.");
      return;
    }
    if (data.paid) {
      setSuccessMessage("This order was already paid.");
      setPageState(2);
    }
    if (data.pay_error) {
      setPageState(-1);
      setErrorMessage(data.pay_error);
      return;
    }
  }, [setErrorMessage, initialStage, setSuccessMessage, setPageState, data]);

  useEffect(() => {
    if (navigator) {
      const ua = navigator.userAgent;
      /* MSIE used to detect old browsers and Trident used to newer ones*/
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

      if (is_ie) {
        setPageState(-1);
        setErrorMessage(
          "Unfortunately, Internet Explorer is not fully supported by payment systems. Please use another browser in order to proceed"
        );
      }
    }
  }, [setErrorMessage, setSuccessMessage]);

  /**
   * updates phone error status
   */
  useEffect(() => {
    setPhoneError(!isPossiblePhoneNumber(phoneNumber));
    if (data.payment_link)
      !isPossiblePhoneNumber(phoneNumber)
        ? setError("phone", { message: "Invalid phone number" })
        : clearErrors("phone");
  }, [clearErrors, data.payment_link, phoneNumber, setError, setPhoneError]);

  /**
   * this function should be used for handling the payment intent on the server side
   * it finishes card payment flow and returns transaction details, including charge id(s)
   */
  const sendStripeChargeRequest = useCallback(
    async (paymentMethod) => {
      let res = null;
      const paymentIntent = await createIntent();
      if (paymentMethod && paymentIntent && serverURL) {
        try {
          res = await stripe.confirmCardPayment(
            paymentIntent.client_secret,
            {
              payment_method: paymentMethod.id,
            },
            { handleActions: false }
          );

          if (res.error) {
            console.debug(res.error);
            setPageState(-1);
            setErrorMessage(
              res.error.message || "Error happened during payment processing..."
            );
            return false;
          } else if (
            res.paymentIntent &&
            res.paymentIntent.status === "requires_action"
          ) {
            res = await stripe.confirmCardPayment(paymentIntent.client_secret);
          }

          if (res.paymentIntent && res.paymentIntent.status === "succeeded") {
            res = await axios.post(serverURL + "/get-intent", {
              payment_intent: paymentIntent.id,
            });
          } else if (res.error) {
            setPageState(-1);
            setErrorMessage(
              res.error.message || "Error happened during payment processing..."
            );
            return false;
          }
        } catch (err) {
          setPageState(-1);
          setErrorMessage(
            err.message || "Error happened during payment processing..."
          );
          return false;
        }

        // checking response
        if (
          res &&
          res.status === 200 && // response is OK
          !res.data.error && // no errors
          res.data.paymentIntent && // payment intent created successfully
          res.data.paymentIntent.status === "succeeded" && // intent confirmed
          res.data.paymentIntent.charges.data.length // charge ids are present
        ) {
          setPageState(2);
          setButtonDisabled(false);
          return true;
        } else if (res) {
          setPageState(-1);
          setErrorMessage(
            typeof res.error === "string"
              ? res.error
              : res.data && res.data.error && res.data.error.message
              ? res.data.error.message
              : "Error happened during payment processing..."
          );
          return false;
        }
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverURL, setButtonDisabled, stripe, phoneNumber]
  );

  /**
   * creates a payment intent on the server side without payment method and confirmation
   */
  const createIntent = useCallback(async () => {
    if (!data || initialStage !== 0) return null;

    // generating metadata values for products
    const productMeta = data.items.slice(0, 15).reduce((res, item, i) => {
      let resCopy = res;
      const decodedTitle = decodeHTML(item.title);
      const title =
        decodedTitle.length > 500
          ? decodedTitle.substring(0, 496) + "..."
          : decodedTitle;
      resCopy[`item_name${i}`] = title;
      resCopy[`item_number${i}`] = item.listing_id.toString();
      resCopy[`item_quantity${i}`] = item.quantity;
      return resCopy;
    }, {});

    // generating shippping information
    const shippingData = {
      address: {
        city: data.shipping.city,
        country: data.shipping.country_iso,
        line1: data.shipping.first_line,
        line2: data.shipping.second_line,
        postal_code: data.shipping.zip,
        state: data.shipping.state,
      },
      phone: phoneNumber,
      name: data.shipping.name,
    };

    // creating a payment intent for both methods (without payment method and confirmation)
    const res = await axios.post(serverURL + "/create-intent", {
      currency: data.currency ? data.currency.toLowerCase() : "usd",
      amount: Math.ceil(data.total_amount * 100),
      shipping: shippingData,
      metadata: {
        tax: data.tax || 0,
        vat: data.vat || 0,
        invoice: data.invoice || "",
        shop_type: "etsy",
        ...productMeta,
      },
    });

    if (
      res.status === 200 && // response is OK
      res.data.paymentIntent
    ) {
      return res.data.paymentIntent;
    } else {
      setPageState(-1);
      setErrorMessage("Error happened during Stripe initialization...");
      return null;
    }
  }, [data, initialStage, phoneNumber, serverURL]);

  const getBraintreeToken = useCallback(async () => {
    if (useBraintree) {
      // creating a payment intent for both methods (without payment method and confirmation)
      const res = await axios.get(serverURL + "/get-braintree-token");
      if (
        res.status === 200 && // response is OK
        res.data.token
      ) {
        setBraintreeToken(res.data.token);
      } else {
        setPageState(-1);
        setErrorMessage("Error happened during Braintree initialization...");
        logError("Error happened during Braintree initialization...");
        return null;
      }
    }
  }, [useBraintree, serverURL, logError]);

  /**
   * this useEffect hook is used for the Payment Request button:
   * we create a payment request to collect payment data and check if client is ready for payment
   * if any check fails, we're going to use normal card checkout form
   */
  useEffect(() => {
    if (pageState > 1 || pageState < 0) return;
    if (!stripe || !data || !serverURL || data.paid) {
      // if (!stripe || !data || !serverURL) {
      return;
    }

    // get braintree token and set it here for future use
    if (!braintreeToken) getBraintreeToken();

    // check if amount >= $0.60
    const amount = Math.ceil(data.total_amount * 100);
    if (amount < 60) {
      setErrorMessage("Unfortunately, only orders from $0.60 may be paid.");
      setPageState(-1);
      return;
    }

    // change state when stripe is loaded and data object is not empty
    setPageState(1);

    // creating payment request (for Payment Request Button)
    const pr = stripe.paymentRequest({
      country: "US",
      currency: data.currency ? data.currency.toLowerCase() : "usd",
      total: {
        label: `Etsy invoice ${data.invoice}`,
        amount: Math.ceil(data.total_amount * 100),
      },
    });

    // checking if we client is ready for Payment request
    // if yes, render the payment button, otherwise card checkout form
    pr.canMakePayment().then((canMakePaymentRes) => {
      if (!canMakePaymentRes) {
        setButtonPayment(false);
      } else {
        setPaymentRequest(pr);
      }
    });

    // if payment method and intent are valid, try to confirm the payment
    pr.on("paymentmethod", async (event) => {
      if (event.paymentMethod) {
        const res = await sendStripeChargeRequest(event.paymentMethod);
        if (res) {
          event.complete("success");
        } else {
          event.complete("fail");
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    stripe,
    setPaymentRequest,
    setButtonPayment,
    setPageState,
    setErrorMessage,
    sendStripeChargeRequest,
    phoneNumber,
    useBraintree,
    braintreeToken,
  ]);

  useEffect(() => {
    if (data && data.payment_link && !customPaymentLink) {
      setPaypalBtnCreating(true);
      const createPPButton = async () => {
        const res = await axios.post(
          serverURL + `/generate-payment-link-paypal-btn/${invoice}`,
          data
        );
        if (res.status === 200 && res.data.button) {
          setCustomPaymentLink(res.data.button);
        } else {
          setPageState(-1);
          setErrorMessage("Error happened during PayPal initialization...");
          logError("Error happened during PayPal initialization...");
          return null;
        }
      };
      createPPButton();
      setPaypalBtnCreating(false);
    }
  }, [
    addressConfirmed,
    customPaymentLink,
    data,
    invoice,
    logError,
    paymentService,
    serverURL,
  ]);

  /**
   * this function is used for card checkout form only.
   * we check whether card is valid or not.
   * if ok, we send data to server to create and confirm the payment
   */
  const handleCardSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        if (error) {
          // only card input form problems, don't throw exceptions!
          console.log("[card error]", error);
        } else if (paymentMethod) {
          setButtonDisabled(true); // setting status to `processing`, it will disable the `Pay` button
          sendStripeChargeRequest(paymentMethod);
        } else {
          setPageState(-1);
          setErrorMessage("Error happened during card checking...");
        }
      } catch (err) {
        setPageState(-1);
        setErrorMessage("Error happened during payment processing...");
      }
    },
    [stripe, elements, sendStripeChargeRequest, setButtonDisabled]
  );

  const changeNumber = useCallback(
    async (phone) => {
      if (paymentRequest) await paymentRequest.update({});
      setPhoneNumber(phone);
    },
    [paymentRequest, setPhoneNumber]
  );

  useEffect(() => {
    if (!document || pageState !== 1 || !braintreeToken || !addressConfirmed)
      return;

    // check if braintree container is empty
    let container = document.querySelector("#braintree-container");
    if (!isNodeEmpty(container)) {
      return;
    }

    let dropInOptions = {
      authorization: braintreeToken,
      container: "#braintree-container",
      card: {
        cardholderName: {
          required: true,
        },
      },
      dataCollector: {
        paypal: true,
      },
      threeDSecure: true,
    };

    dropin.create(dropInOptions, function (err, dropinInstance) {
      if (err) {
        setErrorMessage(getBraintreeErrorMessage(err));
        logError(getBraintreeErrorMessage(err));
        setPaymentService("stripe");
        setUseBrainrtee(false);
        return;
      }

      dropinInstance.on("paymentMethodRequestable", function () {
        setBraintreeButtonDisabled(false);
      });

      dropinInstance.on("noPaymentMethodRequestable", function () {
        setBraintreeButtonDisabled(true);
      });

      const eventHandler = (event) => {
        dropinInstance.requestPaymentMethod(
          {
            threeDSecure: {
              amount: (data.total_amount / data.currency_rate).toFixed(2),
            },
          },
          event,
          function (err, payload) {
            const newData =
              event && event.target && event.target.data
                ? event.target.data
                : data;
            if (err) {
              logError(getBraintreeErrorMessage(err));
              setErrorMessage(getBraintreeErrorMessage(err));
              setPageState(-1);
              return;
            }
            if (payload) {
              (async () => {
                let chargeResult = null;
                try {
                  setBraintreeButtonDisabled(true);
                  setBraintreeRequestProcessing(true);
                  await new Promise((resolve) => setTimeout(resolve, 5000));

                  const products = newData.items.map((item) => {
                    const decodedTitle = decodeHTML(item.title);
                    const title =
                      decodedTitle.length > 35
                        ? decodedTitle.substring(0, 32) + "..."
                        : decodedTitle;
                    const desc =
                      decodedTitle.length > 127
                        ? decodedTitle.substring(0, 124) + "..."
                        : decodedTitle;
                    return {
                      kind: "debit",
                      name: title,
                      description: desc,
                      productCode: item.listing_id.toString(),
                      quantity: item.quantity.toString(),
                      unitAmount: item.price.toString(),
                      totalAmount: (item.quantity * item.price).toString(),
                    };
                  });

                  chargeResult = await axios.post(
                    serverURL + "/braintree-charge",
                    {
                      paymentMethodNonce: payload.nonce,
                      deviceData: payload.deviceData,
                      amount: (
                        newData.total_amount / newData.currency_rate
                      ).toFixed(2),
                      taxAmount: (
                        ((newData.tax || 0) + (newData.vat || 0)) /
                        newData.currency_rate
                      ).toFixed(2),
                      shippingAmount: (
                        (newData.shipping_cost || 0) / newData.currency_rate
                      ).toFixed(2),
                      discountAmount: (
                        newData.discount / newData.currency_rate
                      ).toFixed(2),
                      shipping: {
                        firstName: newData.shipping.name,
                        streetAddress: `${newData.shipping.first_line} ${newData.shipping.second_line}`,
                        locality: newData.shipping.city,
                        region: newData.shipping.state,
                        postalCode: newData.shipping.zip,
                        countryCodeAlpha2: newData.shipping.country_iso,
                      },
                      customFields: {
                        invoice_number: invoice,
                        buyer_phone:
                          event && event.target.phoneNumber
                            ? event.target.phoneNumber
                            : phoneNumber,
                        source_shop: "etsy",
                        source_service: "checkout",
                        source_service_url: "checkout.seller-online.net",
                        currency: newData.currency
                          ? newData.currency.toUpperCase()
                          : "USD",
                        currency_rate: newData.currency_rate,
                        original_amount: newData.total_amount.toFixed(2),
                        original_tax_amount: (
                          (newData.tax || 0) + (newData.vat || 0)
                        ).toFixed(2),
                        original_shipping_amount: (
                          newData.shipping_cost || 0
                        ).toFixed(2),
                        original_discount_amount: (
                          newData.discount || 0
                        ).toFixed(2),
                      },
                      lineItems: products,
                    },
                    "post"
                  );
                } catch (err) {
                  setErrorMessage(
                    `Braintree error: Your payment method seems to be ok but charge request failed`
                  );
                  logError(
                    `Braintree error: Your payment method seems to be ok but charge request failed`
                  );
                  setBraintreeButtonDisabled(false);
                  setBraintreeRequestProcessing(false);
                  setPageState(-1);
                  return;
                }

                if (
                  !chargeResult ||
                  !chargeResult.data ||
                  !chargeResult.data.data
                ) {
                  setErrorMessage(
                    `Braintree error: Payment failed. Bad response from server`
                  );
                  logError(
                    `Braintree error: Payment failed. Bad response from server`
                  );
                  setBraintreeButtonDisabled(false);
                  setBraintreeRequestProcessing(false);
                  setPageState(-1);
                  return;
                } else if (chargeResult.data && chargeResult.data.data) {
                  const { success, result } = chargeResult.data.data;
                  if (!success || result.errors) {
                    setErrorMessage(
                      `Braintree error: Payment failed. ${result.message}`
                    );
                    logError(
                      `Braintree error: Payment failed. ${result.message}`
                    );
                    setBraintreeButtonDisabled(false);
                    setBraintreeRequestProcessing(false);
                    setPageState(-1);
                    return;
                  } else {
                    setBraintreeButtonDisabled(false);
                    setBraintreeRequestProcessing(false);
                    setPageState(2);
                    return;
                  }
                }
              })();
            }
          }
        );
      };
      // setBraintreeEventHandler(eventHandler);
      braintreeButtonRef.current.addEventListener("click", eventHandler);
    });
  }, [
    braintreeToken,
    data,
    invoice,
    logError,
    pageState,
    addressConfirmed,
    phoneNumber,
    serverURL,
    setErrorMessage,
    setPageState,
    setUseBrainrtee,
  ]);

  useEffect(() => {
    braintreeButtonRef.current.data = data;
    braintreeButtonRef.current.phoneNumber = phoneNumber;
  }, [phoneNumber, data]);

  useEffect(() => {
    setHideBraintree(
      !(paymentService === "braintree" && addressConfirmed && pageState === 1)
    );
  }, [paymentService, addressConfirmed, pageState, setHideBraintree]);

  let serviceButtons = [
    {
      value: "stripe",
      label: "Stripe",
      title: "Stripe",
    },
  ];
  if (!data.payment_link) {
    serviceButtons.push({
      value: "paypal",
      label: "Paypal",
      title: "Paypal",
    });
  }
  if (useBraintree) {
    serviceButtons = [
      {
        value: "braintree",
        label: `Credit Card`,
        title: `Credit Card`,
      },
      ...serviceButtons,
    ];
  }

  return (
    <>
      {pageState === 1 && !data.payment_link && (
        <>
          <span className="phone-desc-text">
            <b>ⓘ</b> Phone number is required for international delivery.
          </span>
          <div className="phone-wrapper">
            <PhoneInput
              disabled={addressConfirmed}
              international
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={changeNumber}
              flags={flags}
              defaultCountry={
                data && data.shipping && data.shipping.country_iso
                  ? data.shipping.country_iso
                  : "US"
              }
              numberInputProps={{
                autoFocus: true,
              }}
            />
            {!addressConfirmed ? (
              <button
                className="phone-edit-button"
                type="submit"
                disabled={phoneError}
                onClick={(e) => {
                  e.preventDefault();
                  setAddressConfirmed(true);
                }}
              >
                Apply
              </button>
            ) : (
              <button
                className="phone-edit-button"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setAddressConfirmed(false);
                }}
              >
                Edit
              </button>
            )}
          </div>
          {phoneError && (
            <span className="phone-error-text">
              Please enter a valid phone number
            </span>
          )}
        </>
      )}
      {pageState === 1 && data.payment_link && (
        <>
          {pageState === 1 &&
            customPaymentLink &&
            (paypalBtnCreating ? (
              <p>Loading...</p>
            ) : (
              <div className="payment-links-paypal-container">
                <PayPalButton link={customPaymentLink} />
                <p className="paypal-or-text">
                  <span>OR</span>
                </p>
              </div>
            ))}
          <div className="payment-links-form-text address-desc-text">
            <p>
              <b>Fill the form in order to use </b>
            </p>
            <PaymentIcons />
          </div>
          <div className="address-form-wrapper">
            <form onSubmit={handleSubmit(onAddressSubmit)}>
              <div
                className={`inner-wrapper ${
                  addressConfirmed ? "disabled-div" : ""
                }`}
              >
                {ADDRESSFORMFIELDS.map((field) => (
                  <Fragment key={`address-form-field-${field.name}`}>
                    <span className="address-field-name">{`${field.displayName}`}</span>
                    {field.type === "input" && (
                      <input
                        name={field.name}
                        ref={register({
                          required: field.required
                            ? typeof field.required === "string"
                              ? field.required
                              : "Required field"
                            : false,
                          pattern: {
                            value: field.validation,
                            message: field.error
                              ? field.error
                              : `Invalid ${field.displayName}`,
                          },
                        })}
                      />
                    )}
                    {field.type === "select" && (
                      <select
                        defaultValue="US"
                        name={field.name}
                        ref={register({
                          required: field.required ? "Required field" : false,
                        })}
                      >
                        {field.options}
                      </select>
                    )}
                    {errors[field.name] && (
                      <span className="address-error-text">
                        {errors[field.name].message}
                      </span>
                    )}
                  </Fragment>
                ))}
                <span className="address-field-name">Phone number</span>
                <PhoneInput
                  international
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={changeNumber}
                  flags={flags}
                  defaultCountry={
                    data && data.shipping && data.shipping.country_iso
                      ? data.shipping.country_iso
                      : "US"
                  }
                />
              </div>
              {errors.phone && (
                <span className="address-error-text">
                  {errors.phone.message}
                </span>
              )}
              {!addressConfirmed ? (
                <button type="submit">Submit</button>
              ) : (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddressConfirmed(false);
                  }}
                >
                  Edit
                </button>
              )}
            </form>
          </div>
        </>
      )}
      <div
        className={`payment-container ${
          !addressConfirmed ? "disabled-div" : ""
        }`}
      >
        {pageState === 1 && !addressConfirmed && (
          <div className="payment-placeholder">
            Please provide valid{" "}
            {data.payment_link
              ? "contact details and apply them"
              : "phone number and apply it"}{" "}
            in order to see the payment interface
          </div>
        )}
        {pageState === 1 && addressConfirmed && (
          <div className="payment-service-buttons-outer">
            <p
              style={{ marginTop: 20 }}
              className="choose-payment-service-text"
            >
              Choose payment service:
            </p>
            <ToggleButtonGroup
              func={setPaymentService}
              active={paymentService}
              buttons={serviceButtons}
              styles={{
                minWidth: 250,
              }}
            />
          </div>
        )}
        {pageState === 1 &&
          !buttonPayment &&
          addressConfirmed &&
          paymentService === "stripe" && (
            <form onSubmit={handleCardSubmit}>
              <CardElement
                options={{
                  disabled: phoneError,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <button type="submit" disabled={!stripe || buttonDisabled}>
                {buttonDisabled
                  ? "Processing..."
                  : `Pay ${formatPrice(data.total_amount, data.currency)}`}
              </button>
            </form>
          )}
        {pageState === 1 &&
          buttonPayment &&
          paymentRequest &&
          addressConfirmed &&
          paymentService === "stripe" && (
            <form>
              {paymentRequest && (
                <PaymentRequestButtonElement
                  options={{
                    ...ELEMENT_OPTIONS,
                    paymentRequest,
                  }}
                />
              )}
            </form>
          )}
        <div
          className="braintree-outer-container"
          style={hideBraintree ? { display: "none" } : {}}
        >
          <div id="braintree-container" />
          <button
            id="braintree-button"
            ref={braintreeButtonRef}
            disabled={braintreeButtonDisabled || braintreeRequestProcessing}
            onClick={() => setBraintreeButtonDisabled(true)}
          >
            Purchase
          </button>
        </div>

        {pageState === 1 &&
          data.paypal_link &&
          addressConfirmed &&
          paymentService === "paypal" && (
            <PayPalButton link={data.paypal_link} />
          )}
      </div>
      {/* Stripe loading */}
      {pageState === 0 && (
        <SuccessResult>Waiting for Stripe initializing...</SuccessResult>
      )}
      {/* error happened */}
      {pageState === -1 && errorMessage && (
        <ErrorResult>{errorMessage}</ErrorResult>
      )}
      {/* payment was successful */}
      {pageState === 2 && <SuccessResult>{successMessage}</SuccessResult>}
    </>
  );
};
export default CheckoutForm;
