import React from "react";

export const decodeHTML = (html) => {
  let txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const formatPrice = (price, currency = "USD") =>
  price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: currency,
  });

export const ErrorResult = ({ children }) => (
  <div className="error">{children}</div>
);

export const SuccessResult = ({ children }) => (
  <div className="result">{children}</div>
);

export const Message = ({ children }) => <div className="msg">{children}</div>;

export const getBraintreeErrorMessage = (err) => {
  let errData = err;
  if (errData) {
    if (errData.details && errData.details.originalError) {
      errData = "Braintree error: " + errData.details.originalError;
    } else if (errData.error && errData.error.message) {
      return "Braintree error: " + errData.error.message;
    } else if (errData.message) {
      return "Braintree error: " + errData.message;
    } else {
      return "Braintree error: Failed to handle the payment";
    }
  }
};

export const isNodeEmpty = (node) => !node || node.textContent.trim() === "";
