import React from "react";
import "./copyright.scss";

export default () => (
  <div className="copyright">
    <div className="copyright__logo">
      <svg viewBox="0 0 410.8 123.3" width="120" height="40">
        <g fill="#FFF">
          <path d="M33.3 28.7c5.2-3 10.7-3.6 15.8-1.7 4.1 1.5 7.6 4.5 10.6 7.9.1-10.4.1-20.7.2-31.1C59.9 1.7 58.2 0 56 0H7.2c-2 0-3.6 1.5-3.8 3.4C1.4 19.6.3 35.9 0 52.2c0 2.2 1.7 3.9 3.9 3.9h25.7c-.7-.9-1.3-1.8-1.9-2.8-5.4-9.3-3.1-19.6 5.6-24.6zM83.3 27c5.1-1.9 10.6-1.3 15.8 1.7 8.6 5 11 15.3 5.6 24.6-.6 1-1.2 1.9-1.9 2.8h24.7c2.2 0 3.9-1.8 3.9-3.9-.3-16.3-1.4-32.6-3.4-48.8-.2-1.9-1.9-3.4-3.8-3.4H75.4c-2.1 0-3.9 1.7-3.8 3.9.1 10.7.2 21.5.2 32.3 3.1-4 6.9-7.4 11.5-9.2zM55.3 98.9l-8.5 1.3c-2.4-15.3 1.1-26 5.7-33.1H3.9C1.7 67.1 0 68.9 0 71c.3 16.3 1.4 32.6 3.4 48.8.2 1.9 1.9 3.4 3.8 3.4H56c2.1 0 3.9-1.7 3.8-3.9-.2-15.7-.3-31.5-.3-47.3-3.5 5.8-6.2 14.4-4.2 26.9zM127.5 67.1H76.9c4.7 6.4 12 13.7 21.1 14.8l-1.1 8.5C86.1 89 77.6 81.6 71.8 74.5c0 15-.1 29.9-.3 44.8 0 2.1 1.7 3.9 3.8 3.9h48.8c2 0 3.6-1.5 3.8-3.4 1.9-16.2 3.1-32.5 3.4-48.8.1-2.1-1.6-3.9-3.8-3.9z"></path>
          <path d="M46 35.1c-1-.4-2.1-.6-3.1-.6-1.7 0-3.5.5-5.4 1.6C32 39.3 32.7 45 35 49c1.8 3.1 4.6 5.7 8.3 7.1h12.3c2.1 0 3.8-1.7 3.8-3.8v-1.5c-2.8-6-7.6-13.6-13.4-15.7zM97.3 49c2.3-3.9 3-9.7-2.5-12.9-3-1.7-5.7-2.1-8.5-1-6.4 2.4-11.7 11.5-14.5 17.7.3 1.9 1.9 3.3 3.8 3.3H89c3.7-1.4 6.5-4 8.3-7.1zM142.6 24.6c0-3.3.8-5.8 2.4-7.6 1.6-1.8 4-2.7 7.1-2.7s5.5.9 7.1 2.7 2.4 4.3 2.4 7.6v1.3h-6.1v-1.7c0-2.7-1.1-4-3.2-4-2.2 0-3.2 1.3-3.2 4 0 1.1.2 2.2.7 3.2s1.1 1.9 1.8 2.7c.7.8 1.6 1.6 2.5 2.4s1.8 1.6 2.7 2.5 1.7 1.8 2.4 2.8 1.4 2.2 1.8 3.5c.5 1.4.7 2.8.7 4.4 0 3.3-.8 5.8-2.5 7.6-1.6 1.8-4 2.7-7.2 2.7-3.1 0-5.5-.9-7.2-2.7-1.6-1.8-2.5-4.3-2.5-7.6v-2.5h6.1v2.9c0 2.6 1.1 4 3.4 4s3.4-1.3 3.4-4c0-1.5-.4-2.9-1.3-4.3-.9-1.3-1.9-2.6-3.2-3.6-1.2-1.1-2.5-2.2-3.7-3.4-1.3-1.2-2.3-2.7-3.2-4.4-.8-1.7-1.2-3.7-1.2-5.8zM171.9 20.6V32h8.9v5.8h-8.9v12h11.2v5.8h-17.7V14.8h17.7v5.8h-11.2zM187.1 55.6V14.8h6.5v35h10.7v5.8h-17.2zM207.1 55.6V14.8h6.5v35h10.7v5.8h-17.2zM233.5 20.6V32h8.9v5.8h-8.9v12h11.2v5.8H227V14.8h17.7v5.8h-11.2zM268.9 55.6h-6.6c-.4-1.2-.6-2.8-.6-5v-6.4c0-1.9-.3-3.2-1-4S259 39 257.5 39h-2.2v16.6h-6.5V14.8h9.8c3.3 0 5.8.8 7.3 2.3s2.3 3.9 2.3 7.1v3.2c0 4.2-1.4 7-4.2 8.3 2.9 1.2 4.3 4.1 4.3 8.7v6.3c-.1 2.2.1 3.8.6 4.9zm-10.6-35h-3.1v12.5h2.5c1.3 0 2.3-.3 2.9-1s1-1.8 1-3.3v-4c0-2.8-1.1-4.2-3.3-4.2zM272.1 38.1v-5.8h11.8v5.8h-11.8zM287.8 45.8V24.6c0-3.3.8-5.8 2.5-7.6s4.1-2.7 7.3-2.7 5.6.9 7.3 2.7c1.7 1.8 2.5 4.3 2.5 7.6v21.2c0 3.3-.8 5.8-2.5 7.6s-4.1 2.7-7.3 2.7-5.6-.9-7.3-2.7c-1.7-1.8-2.5-4.3-2.5-7.6zm6.5-21.6v22.1c0 2.7 1.1 4 3.4 4 2.2 0 3.4-1.3 3.4-4V24.2c0-2.7-1.1-4-3.4-4-2.3-.1-3.4 1.3-3.4 4zM325.5 55.6L317.4 26v29.6h-5.8V14.8h8.1l6.7 24.4V14.8h5.8v40.8h-6.7zM336.8 55.6V14.8h6.5v35H354v5.8h-17.2zM356.7 55.6V14.8h6.5v40.8h-6.5zM381.8 55.6L373.7 26v29.6h-5.8V14.8h8.1l6.7 24.4V14.8h5.8v40.8h-6.7zM399.6 20.6V32h8.9v5.8h-8.9v12h11.2v5.8h-17.7V14.8h17.7v5.8h-11.2z"></path>
          <g>
            <path d="M142.6 78.6c0-3.3.8-5.8 2.4-7.6 1.6-1.8 3.9-2.7 7.1-2.7 3.1 0 5.5.9 7.1 2.7 1.6 1.8 2.4 4.3 2.4 7.6v1.3h-6.1v-1.7c0-2.7-1.1-4-3.2-4s-3.2 1.3-3.2 4c0 1.1.2 2.2.7 3.2s1.1 1.9 1.8 2.7c.7.8 1.6 1.6 2.5 2.4s1.8 1.6 2.7 2.5 1.7 1.8 2.4 2.8 1.3 2.2 1.8 3.5.7 2.8.7 4.4c0 3.3-.8 5.8-2.5 7.6s-4 2.7-7.1 2.7-5.5-.9-7.1-2.7-2.5-4.3-2.5-7.6v-2.5h6.1v2.9c0 2.6 1.1 4 3.3 4s3.3-1.3 3.3-4c0-1.5-.4-2.9-1.3-4.3-.9-1.3-1.9-2.6-3.1-3.6-1.2-1.1-2.5-2.2-3.7-3.4-1.2-1.2-2.3-2.7-3.2-4.4s-1.3-3.7-1.3-5.8zM166.2 68.8h6.4v31.5c0 2.6 1.1 4 3.3 4s3.3-1.3 3.3-4V68.8h6.1v31.1c0 3.3-.8 5.8-2.5 7.6-1.6 1.8-4 2.7-7.1 2.7s-5.5-.9-7.1-2.7c-1.6-1.8-2.5-4.3-2.5-7.6V68.8h.1zM200.5 68.8c6.4 0 9.6 3.4 9.6 10.1v5.3c0 6.7-3.2 10.1-9.6 10.1h-3v15.3H191V68.8h9.5zm0 5.8h-3v13.8h3c1 0 1.8-.3 2.4-.9.5-.6.8-1.6.8-3v-6.1c0-1.4-.3-2.4-.8-3-.6-.5-1.4-.8-2.4-.8zM220.7 74.6V86h8.8v5.8h-8.8v12h11.1v5.8h-17.5V68.8h17.5v5.8h-11.1zM257 109.6h-6.5c-.4-1.2-.6-2.8-.6-5v-6.4c0-1.9-.3-3.2-1-4s-1.7-1.2-3.2-1.2h-2.2v16.6h-6.4V68.8h9.7c3.3 0 5.7.8 7.2 2.3s2.3 3.9 2.3 7.1v3.2c0 4.2-1.4 7-4.2 8.3 2.8 1.2 4.3 4.1 4.3 8.7v6.3c-.1 2.2.2 3.8.6 4.9zm-10.4-35h-3.1v12.5h2.5c1.3 0 2.2-.3 2.9-1 .6-.7 1-1.8 1-3.3v-4c-.1-2.8-1.1-4.2-3.3-4.2zM270.7 68.8l4.7 29 4.4-29h8.9v40.8h-6.1V80.3l-4.4 29.3h-6.1l-4.8-28.9v28.9h-5.6V68.8h9zM308.9 68.8l6.5 40.8H309l-1.1-7.4H300l-1.1 7.4H293l6.5-40.8h9.4zm-5 7.2l-3.1 20.7h6.2L303.9 76zM339.6 109.6h-6.5c-.4-1.2-.6-2.8-.6-5v-6.4c0-1.9-.3-3.2-1-4s-1.7-1.2-3.2-1.2h-2.2v16.6h-6.4V68.8h9.7c3.3 0 5.7.8 7.2 2.3s2.3 3.9 2.3 7.1v3.2c0 4.2-1.4 7-4.2 8.3 2.8 1.2 4.3 4.1 4.3 8.7v6.3c-.1 2.2.2 3.8.6 4.9zm-10.4-35h-3.1v12.5h2.5c1.3 0 2.2-.3 2.9-1 .6-.7 1-1.8 1-3.3v-4c0-2.8-1.1-4.2-3.3-4.2zM359.1 109.6l-6.2-16.3-2 3.7v12.5h-6.4V68.8h6.4v17.8l8.4-17.8h6.4l-9 18.2 8.9 22.6h-6.5zM376.3 74.6V86h8.8v5.8h-8.8v12h11.1v5.8h-17.5V68.8h17.5v5.8h-11.1zM390.8 74.6v-5.8h19.8v5.8h-6.7v35h-6.4v-35h-6.7z"></path>
          </g>
        </g>
      </svg>
    </div>
    <div className="copyright__text">© 2019-2020 Solma</div>
  </div>
);
