import React from "react";
import { ReactComponent as MarketLogo } from "./market.svg";
import { ReactComponent as SellerLogo } from "./seller.svg";
import "./index.scss";

export default () => (
  <div className="header">
    <div className="header__inner app-container">
      <div className="header__market-logo">
        <MarketLogo />
      </div>
      <div className="header__slogan">online checkout</div>
      <div className="header__logo">
        <SellerLogo />
      </div>
    </div>
  </div>
);
