import React from "react";
import { decodeHTML, formatPrice } from "../../utils";
import "./index.scss";

const ProductCard = ({ product, currency }) => {
  return (
    <div className="product-card">
      <div className="product-card__data">
        <div className="product-card__img">
          {product.image_type ? (
            <img
              src={`data:image/jpeg;${product.image_type},${product.image}`}
              alt={decodeHTML(product.title)}
            ></img>
          ) : (
            <img src={product.image} alt={decodeHTML(product.title)}></img>
          )}
        </div>
        <div className="product-card__info">
          {product.listing_id && (
            <div className="product-card__id">
              <span className="product-card__info-key">Item ID:</span>{" "}
              {product.listing_id}
            </div>
          )}
          <div className="product-card__desc">
            <p>{decodeHTML(product.title)}</p>
          </div>
          <div className="product-card__count">
            <span className="product-card__info-key">Quantity:</span>{" "}
            {product.quantity}
          </div>
          {product.description && product.description.length && (
            <div className="product-card__personalization">
              <span className="product-card__info-key">About the product:</span>{" "}
              <div style={{whiteSpace: "pre-line"}}>
                {decodeHTML(product.description)}
              </div>
            </div>
          )}
          {product.personalization && product.personalization.length && (
            <div className="product-card__personalization">
              <span className="product-card__info-key">Personalization:</span>{" "}
              {decodeHTML(product.personalization)}
              <div className="product-card__variations">
                {product.variations &&
                  product.variations.map((variation, i) => (
                    <div
                      className="product-card__variation-item"
                      key={`product-var-${product.listing_id}-${i}`}
                    >
                      <span className="product-card__info-key">
                        {decodeHTML(variation.key)}:
                      </span>{" "}
                      {decodeHTML(variation.val)}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="product-card__price">
        <div className="product-card__price-item">
          {formatPrice(product.price, currency)}
        </div>
        <div className="product-card__price-mult">x {product.quantity}</div>
        <div className="product-card__price-summ">
          = <b>{formatPrice(product.price * product.quantity, currency)}</b>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
